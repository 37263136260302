var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      height: "80vh"
    }
  }, [_c("iframe", {
    attrs: {
      width: "100%",
      height: "100%",
      src: "http://127.0.0.1:8888/form-generator/#/",
      frameborder: "0"
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };